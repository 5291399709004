<template>
  <section class="section container">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li class="is-active">
          <a>Administração</a>
        </li>
        <li class="is-active">
          <a>Pagamentos</a>
        </li>
      </ul>
    </nav>

    <b-collapse class="card m-b-md" :open="false" aria-id="filterContent">
      <div
        slot="trigger"
        slot-scope="props"
        class="card-header"
        role="button"
        aria-controls="filterContent"
      >
        <p class="card-header-title">Filtro</p>
        <a class="card-header-icon">
          <b-icon :icon="props.open ? 'caret-down' : 'caret-up'"></b-icon>
        </a>
      </div>
      <div class="card-content">
        <form @submit.prevent="get">
          <b-field label="Data de Inicio">
            <b-datepicker
              :month-names="datePicker[0]"
              :day-names="datePicker[1]"
              v-model="query.initDate"
              icon="calendar-alt"
            ></b-datepicker>
          </b-field>

          <b-field label="Data de Fim">
            <b-datepicker
              :month-names="datePicker[0]"
              :day-names="datePicker[1]"
              v-model="query.endtDate"
              icon="calendar-alt"
            ></b-datepicker>
          </b-field>

          <b-field label="Grupo" v-if="!hasProfile">
            <b-select v-model="query.team" placeholder="Grupo">
              <option v-for="team in teams" :key="team._id" :value="team._id">{{
                team.name
              }}</option>
              <option :value="null">Nenhum</option>
            </b-select>
          </b-field>

          <b-field label="Curso">
            <b-select v-model="query.curso" placeholder="Curso">
              <option
                v-for="course in courses"
                :key="course._id"
                :value="course._id"
                >{{ course.name }}</option
              >
              <option :value="null">Nenhum</option>
            </b-select>
          </b-field>

          <b-field label="Meio de pagamento">
            <b-select v-model="query.payament" placeholder="Curso">
              <option value="contrato">Contrato</option>
              <option value="bonus">bonus</option>
              <option value="boleto">boleto</option>
              <option value="cartão de crédito">Cartão de crédito</option>
              <option value="deposito">Deposito</option>
              <option value="transferencia">Transferencia</option>
              <option value="teste">Teste</option>
              <option :value="null">Nenhum</option>
            </b-select>
          </b-field>

          <b-field label="Status">
            <b-select v-model="query.status" placeholder="Curso">
              <option value="refused">Recusado</option>
              <option value="waiting_payment">Aguardando pagamento</option>
              <option value="paid">Pago</option>
            </b-select>
          </b-field>

          <b-field label="Usuário">
            <Multiselect
              v-model="users"
              id="ajax"
              label="name"
              track-by="name"
              placeholder="Digite para pesquisar"
              open-direction="bottom"
              :options="searchList"
              :multiple="false"
              :searchable="true"
              :loading="isLoadingMultiSelect"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              :options-limit="300"
              :limit="3"
              :max-height="600"
              :show-no-results="false"
              :hide-selected="true"
              :preselect-first="false"
              @search-change="getBySearch"
              selectLabel="Pressione enter para selecionar"
              deselectLabel="Pressione enter para desmarcar"
              selectedLabel="Selecionado"
            >
              <template slot="noOptions">Lista vazia</template>
            </Multiselect>
          </b-field>

          <button class="button is-primary">Buscar</button>
          <button
            type="button"
            class="button is-danger m-l-sm"
            @click="(query = {}), (users = [])"
          >
            Limpar
          </button>
        </form>
      </div>
    </b-collapse>

    <h1>Total: {{ total }}</h1>

    <b-table
      :data="filter"
      default-sort-direction="asc"
      :striped="true"
      :hoverable="true"
      :loading="isLoading"
    >
      <template slot-scope="props">
        <b-table-column field="user.name" label="Nome" sortable>{{
          props.row.user.name
        }}</b-table-column>
        
        <b-table-column field="user.username" label="Login" sortable>{{
          props.row.user.username
        }}</b-table-column>

        <b-table-column field="course.name" label="Curso" sortable>{{
          props.row.course.name
        }}</b-table-column>

        <b-table-column field="teams" label="Grupo" sortable>{{
          props.row.teams
        }}</b-table-column>

        <b-table-column field="paymentMethod" label="Meio de Pagamento" sortable>{{
          props.row.paymentMethod
        }}</b-table-column>

        <b-table-column field="teams" label="Data de inscrição" sortable>{{
          props.row.createdAt
        }}</b-table-column>

        <b-table-column field="teams" label="Status" sortable>{{
          handleStatus(props.row.paymentStatus)
        }}</b-table-column>

        <b-table-column field="course.price" label="Preço" sortable>{{
          props.row.course.price
        }}</b-table-column>
      </template>
    </b-table>

    <div class="custom">
      <b-pagination
        class="m-t-md"
        :total="total"
        :current.sync="current"
        :simple="false"
        :rounded="false"
        :per-page="perPage"
        aria-next-label="Próxima pagina"
        aria-previous-label="Pagina anterior"
        aria-page-label="Page"
        aria-current-label="Pagina atual"
      ></b-pagination>
    </div>
  </section>
</template>

<script>
import Axios from 'axios';
import { debounce } from 'lodash';

import { hasProfile } from '@/global/auth';
import Multiselect from 'vue-multiselect';
export default {
  components: { Multiselect },
  data() {
    return {
      hasProfile: hasProfile('manager'),
      data: [],
      total: '',
      teams: [],
      users: [],
      query: {},
      courses: {},
      searchList: [],
      current: 1,
      perPage: '',
      search: null,
      datePicker: [
        [
          'Janeiro',
          'Fevereiro',
          'Março',
          'Abril',
          'Maio',
          'Junho',
          'Julho',
          'Agosto',
          'Setembro',
          'Outubro',
          'Novembro',
          'Dezembro',
        ],
        ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      ],
      isLoading: false,
      isLoadingMultiSelect: false,
      columns: [
        {
          field: 'user.name',
          label: 'Nome',
          sortable: true,
        },
        {
          field: 'user.username',
          label: 'Login',
          sortable: true,
        },
        {
          field: 'course.name',
          label: 'Curso',
          sortable: true,
        },
        {
          field: 'teams',
          label: 'Grupo',
          sortable: true,
        },
        {
          field: 'paymentMethod',
          label: 'Meio de Pagamento',
          sortable: true,
        },
        {
          field: 'createdAt',
          label: 'Data de inscrição',
          sortable: true,
        },
        {
          field: 'paymentStatus',
          label: 'Status',
          sortable: true,
        },
        {
          field: 'course.price',
          label: 'Preço',
          sortable: true,
        },
      ],
    };
  },
  methods: {
    getBySearch: debounce(function() {
      this.isLoadingMultiSelect = true;
      if (arguments[0]) {
        Axios.get(
          `/v1/${this.me ? 'users/team/' + this.me.teams[0] : 'users'}?name=${
            arguments[0]
          }`
        )
          .then((res) => {
            this.searchList = res.data.data;
            this.isLoadingMultiSelect = false;
          })
          .catch((erro) => {
            this.isLoadingMultiSelect = false;
            console.error(erro);
          });
      }
      this.isLoadingMultiSelect = false;
    }, 500),
    get() {
      this.isLoading = true;

      let sunrise, sunset;

      if (this.query.initDate) {
        sunrise = new Date(this.query.initDate);
        sunrise.setHours(0);
        sunrise.setMinutes(0);
        sunrise.setUTCSeconds(0);
        sunrise = sunrise.toISOString();
      }

      if (this.query.endtDate) {
        sunset = new Date(this.query.endtDate);
        sunset.setHours(23);
        sunset.setMinutes(59);
        sunset.setSeconds(59);
        sunset = sunset.toISOString();
      }

      Axios.get(
        `/v1/enrolls?page=${this.current}${
          this.me ? '&team=' + this.me.teams[0] : ''
        }${this.query.team ? '&team=' + this.query.team : ''}${
          this.users._id ? '&user=' + this.users._id : ''
        }${this.query.curso ? '&course=' + this.query.curso : ''}${
          this.query.payament ? '&paymentMethod=' + this.query.payament : ''
        }${this.query.initDate ? '&createdAt[$gte]=' + sunrise : ''}${
          this.query.endtDate ? '&createdAt[$lte]=' + sunset : ''
        }${this.query.status ? '&paymentStatus=' + this.query.status : ''}`
      )
        .then((data) => {
          this.data = data.data.data.map((item) => {
            item.createdAt = new Date(item.createdAt).toLocaleDateString(
              'pt-BR'
            );
            item.course.price =
              'R$ ' +
              item.course.price.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
            item.teams = item.user ? item.user.teams[0].name : '';
            item.paymentMethod = item.paymentMethod
              ? item.paymentMethod.toUpperCase()
              : '';
            return item;
          });
          this.total = data.data.total;
          this.perPage = data.data.limit;
          this.isLoading = false;
        })
        .catch((erro) => {
          console.error(erro);
        });
    },
    getCourse() {
      Axios.get(`/v1/courses/`)
        .then((data) => {
          if (data.data) {
            this.courses = data.data.data;
          }
        })
        .catch((erro) => {
          console.error(erro);
        });
    },
    getTeam() {
      Axios.get(`/v1/teams/`, { params: { limit: 50 } })
        .then((data) => {
          if (data.data) {
            this.teams = data.data.data;
          }
        })
        .catch((erro) => {
          console.error(erro);
        });
    },
    getMe() {
      Axios.get(`/v1/users/me`)
        .then((data) => {
          this.me = data.data;
          this.get();
        })
        .catch((erro) => {
          console.error(erro);
        });
    },
    handleStatus(status) {
      if(!status) return 'Sem status';
      if(status === 'refused') return 'Recusado';
      if(status === 'waiting_payment') return 'Aguardando pagamento';
      if(status === 'paid') return 'Pago';
    }
  },
  watch: {
    current(current) {
      this.get(current);
    },
  },
  computed: {
    filter: function() {
      if (!this.data.length) {
        return [];
      }

      return this.data.filter((item) => {
        let props = Object.values(item);
        let search = new RegExp(this.search, 'i');
        return props.some(
          (prop) =>
            !this.search ||
            (typeof prop === 'string'
              ? prop.match(search)
              : prop.toString(10).match(search))
        );
      });
    },
  },
  mounted() {
    this.hasProfile ? this.getMe() : this.get();
    if (!this.hasProfile) this.getTeam();
    this.getCourse();
  },
};
</script>

<style lang="scss" scoped>
.custom {
  display: flex;
  justify-content: flex-end;
}
</style>
